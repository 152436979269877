// костыль для загрузки dev-стилей на Android 2.3
import "./polyfill/bind-polyfill";

// import "exports-loader?window.jQuery!expose-loader?window.$!../external/jquery/js/jquery";

// import "../external/bootstrap/bootstrap.js";
//import "bootstrap/css/bootstrap";
import "./style/bootstrap/karaoke-bootstrap.less";

import "../external/components-font-awesome/css/font-awesome.css";

// стили из thirdparty - чтобы не генерировать новый css-файл небольшого размера
import "../external/angular-carousel/css/angular-carousel.css";